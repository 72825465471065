import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography } from '@material-ui/core';

export const BillingContact = ({ header, contact }) => {
	return (
		<Grid
			container
			direction='row'
			spacing={4}
			justifyContent='flex-start'
			style={{ padding: '4rem 0' }}>
			<Typography
				color='primary'
				variant='h5'
				style={{ fontWeight: 700, marginLeft: '1rem' }}>
				{header}
			</Typography>
			{contact.map((contact, index) => (
				<Grid container direction='row' alignItems='center' item key={index}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FontAwesomeIcon
							icon={['fad', contact.faIcon]}
							style={{
								color: contact.cardAccentColor[0].hexValue ?? '#002D5C',
								height: '35px',
								width: '35px',
								border: `1px solid #E5EAF4`,
								borderRadius: '8px',
								background: '#E5EAF4',
								padding: '6px',
								textAlign: 'center',
								marginRight: '.5rem',
							}}
						/>
						<PortableText
							content={contact._rawContactInfo}
							serializers={{
								normal: ({ children }) => (
									<Typography variant='body1'>
										{children}
									</Typography>
								),
								bold: ({ children }) => (
									<Typography variant='body1' style={{ color: '#002D5C' }}>
										{children}
									</Typography>
								),
								emailPhoneLink: (props) => {
									return (
										<a href={props.emailPhoneHref} style={{ color: '#4B5B69' }}>
											{props.children[0]}
										</a>
									);
								},
							}}
						/>
					</div>
				</Grid>
			))}
		</Grid>
	);
};
