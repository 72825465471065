import React, { useState, useContext, useRef } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { FAQ } from '../components/General/FAQ';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { BillingCard } from '../components/General/Billing/BillingCard';
import { BillingContact } from '../components/General/Billing/BillingContact';
import { SEO } from '../components/SEO';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#5A7184',
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	faqBackground: {
		padding: '4rem 0',
	},
}));

const BillingPage = ({ data, location }) => {
	const billing = data.billing.edges[0].node;
	const classes = useStyles();
	const faqRef = useRef(null);
	const [expanded, setExpanded] = useState(0);

	const { formModalOpen } = useContext(FormModalContext);

	const {
		hero,
		metaTitle,
		metaDescription,
		billingCards,
		faqHeader,
		pageContent,
		contactHeader,
		billingContact,
	} = billing;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Container style={{ marginBottom: '4rem' }}>
				<Grid
					container
					direction='row'
					spacing={2}
					style={{ paddingBottom: '2rem' }}>
					{billingCards.map((card, index) => (
						<BillingCard key={index} card={card} />
					))}
				</Grid>
			</Container>
			<div
				className={classes.faqBackground}
				style={{ background: 'rgb(244, 248, 255)' }}>
				<Container>
					<Typography
						variant='h4'
						color='primary'
						style={{ fontWeight: 700, textAlign: 'center', padding: '2rem 0' }}>
						{faqHeader}
					</Typography>
					{pageContent.map((faq, index) => (
						<FAQ
							key={index}
							faq={faq}
							index={index}
							expanded={expanded}
							setExpanded={setExpanded}
							faqRef={faqRef}
						/>
					))}
				</Container>
			</div>
			<Container>
				<BillingContact header={contactHeader} contact={billingContact} />
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div style={{ marginTop: '12rem' }}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query BillingPageQuery {
		billing: allSanityBilling {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData
							}
						}
						_rawContent
					}
					billingCards {
						_rawTitle
						faIcon
						cardAccentColor {
							hexValue
						}
						body
						cta
						ctaLink
					}
					faqHeader
					pageContent {
						question
						_rawAnswer
					}
					contactHeader
					billingContact {
						faIcon
						cardAccentColor {
							hexValue
						}
						_rawContactInfo
					}
				}
			}
		}
	}
`;

export default BillingPage;
