import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	CardHeader,
	Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		height: '350px',
		padding: '0px',
		borderRadius: '6px',
		// maxWidth: '289px',
		position: 'relative',
		// paddingTop: '2.75rem',
		[theme.breakpoints.down('md')]: {
			height: '345px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '400px',
		},

		transition: 'transform 0.25s',
		// boxShadow:
		// 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 'bold',
		textAlign: 'center',
		margin: '1rem 0 0.5rem 0',
		color: '#002D5C',
	},
	pos: {
		textAlign: 'center',
		color: '#4B5B69',
	},
	content: {
		lineHeight: 1.8,
		height: '165px',
		[theme.breakpoints.down('lg')]: {
			height: '135px',
		},
		[theme.breakpoints.down('md')]: { height: '165px', lineHeight: 1.2 },
		[theme.breakpoints.down('sm')]: { height: '135px' },
		[theme.breakpoints.down('xs')]: { height: '240px' },
	},
	button: {
		padding: '6px 20px',
		opacity: 1,
		fontSize: '1.1rem',
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
	},
}));

export const BillingCard = ({ card }) => {
	const classes = useStyles();

	return (
		<Grid item xs={12} md={6}>
			<Card className={classes.root} elevation={3}>
				<CardHeader
					style={{
						background: card.cardAccentColor[0]?.hexValue ?? '#002D5C',
						padding: '8px',
						width: '100%',
					}}
				/>
				<CardContent
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<Grid
						container
						direction='row'
						alignItems='space-between'
						spacing={2}
						item
						xs={12}>
						<Grid
							container
							item
							direction='row'
							justifyContent='flex-start'
							alignItems='center'>
							<FontAwesomeIcon
								icon={['fad', card.faIcon]}
								style={{
									color: card.cardAccentColor[0]?.hexValue ?? '#002D5C',
									height: '45px',
									width: '45px',
									border: `1px solid #E5EAF4`,
									borderRadius: '8px',
									background: '#E5EAF4',
									padding: '6px',
									textAlign: 'center',
									marginRight: '.5rem',
								}}
							/>
							<PortableText
								content={card._rawTitle}
								serializers={{
									h4: ({ children }) => (
										<Typography
											variant='h6'
											style={{ fontWeight: 550, lineHeight: 1.3 }}>
											{children}
										</Typography>
									),
								}}
							/>
						</Grid>
						<Grid
							container
							direction='row'
							alignItems='flex-start'
							item
							className={classes.content}>
							<Typography variant='body1'>
								{card.body}
							</Typography>
						</Grid>
						<Grid container item>
							<a
								href={card.ctaLink}
								target='_blank'
								rel='noreferrer'
								style={{ textDecoration: 'none' }}>
								<Button
									variant='contained'
									size='large'
									color='primary'
									className={classes.button}
									style={{
										background: card.cardAccentColor[0]?.hexValue ?? '#002D5C',
									}}>
									{card.cta}
								</Button>
							</a>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
